<template>
  <div class='d-flex align-items-center h-100'>
    <div class='stap-inner' v-if="options && options.length > 0">
      <h1><span class='stap-number'>2.</span> Discipline</h1>
      <h5 class='stap-beschrijving'>Voor welke discipline wil je {{aanmeldingNaam}} aanmelden?</h5>
      <div class="row mt-5">
        <div class="col-md-12 d-block d-md-inline-flex">
          <div
            v-for="disciplineOption in options" :key="disciplineOption.id"
            class='card-option'
            @click='setActiviteit($event, disciplineOption)'
            :class="isSelected(disciplineOption.id)"
        >
            {{disciplineOption.titel}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    setActiviteit: function (e, activiteit) {
      this.loading = true
      this.$store.dispatch('aanmelding/setActiviteit', activiteit)
      this.$router.push({ name: 'StapCursus' })
      this.loading = false
    },
    isSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.activiteit && this.$store.state.aanmelding.activiteit.id === id
      }
    }
  },
  computed: {
    aanmeldingNaam () {
      return this.$store.state.aanmelding.client.firstname
    },
    fullfill () {
      return !!this.$store.state.aanmelding.activiteit
    },
    options () {
      return this.$store.state.options.activiteiten
    },
    aanmeldingActiviteit () {
      return this.$store.state.aanmelding.activiteit
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('options/getActiviteiten')
  }
}
</script>
<style scoped>
    .card{
        border: 2px solid #fff;
        cursor: pointer;
        min-height: 100px;
    }
    .card.selected{
        border: 2px solid #00a09f;
    }
</style>
